import React from 'react';


import './App.css'
import { FixedRatioView } from './FixedRatioView'

function App() {

  const queryParameters = new URLSearchParams(window.location.search)
  const experienceDir = queryParameters.get("e") ?? 'Skyscraper-AR';

  const sourceURL = `https://custom.getboostar.com/${experienceDir}/`;

  let height = 1920;
  let width = 1080;
  switch (experienceDir) {

    // if we want custom ratios for certain experiences, set them here.

    default: 
      break;
  }


  return (
    <div className="App">
      <FixedRatioView height={height} width={width} url={sourceURL} />
    </div>
  );
}

export default App;
