
import styled from "styled-components"


function resize(size:any, max:any){ 
  // can we maximize the width without exceeding height limit? 
  let resize_factor = (size.height / ( size.width/max.width)) <= max.height
  ? size.width/max.width
  : size.height/max.height

 return {
    width: size.width/resize_factor, 
    height: size.height/resize_factor, 
    factor:resize_factor,
    scale: (size.width/resize_factor) / size.width, // should be the same as height so only need 1
  }
}



export const FixedRatioView = ({ width, height, url }: any) => {

  let max = {
    width: window.innerWidth,
    height: window.innerHeight
  }
  const setSize = resize({width, height}, max)

  const FixedRatio = styled.div`
    -webkit-transform: scale(${setSize.scale});
    transform: scale(${setSize.scale});
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  `

  const FixedRatioWrapper = styled.div`
    width: ${setSize.width}px;
    margin: 0px auto;
    overflow: hidden;
  `

  return (
    <FixedRatioWrapper>
        <FixedRatio>
          <iframe title="BoostAR Custom Experience" src={url} height={`${height}px`} width={`${width}px`} style={{border:'none'}} allow="camera" className="Wrapper"></iframe>
        </FixedRatio>
      </FixedRatioWrapper>
  );

}


